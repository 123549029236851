<template>
  <div>
    <p class="mb-0">
      Please make an EFT into one of the bank accounts below.
    </p>
    <choose-bank v-if="loadStatus === 'loaded'" :currency="transaction.amounts.currency"></choose-bank>
  </div>
</template>

<script>
import ChooseBank from './ChooseBank'
import { mapState } from 'vuex'

export default {
  components: {
    ChooseBank
  },
  computed: {
    ...mapState({
      record: state => state.transaction,
      transaction: state => state.transaction.transaction
    }),
    loadStatus () {
      return this.record.status
    }
  },
  created () {
    this.$emit('update-can-pay', true)
  }
}
</script>
