<template>
  <div id="payment-choose-cards" class="fee-method">
    <ul class="list-unstyled row mx-n1 mx-md-n3 transaction-select" v-if="user.cards && user.cards.length > 0" v-match-heights="{el: ['p.equal-height']}">
      <li class="col-6 col-md-4 px-1 px-md-3 mb-3" v-for="(record, $index) in user.cards" :key="$index">
        <a href="#" @click.prevent="selected = record._id" class="box" :class="selected === record._id ? 'active' : ''">
          <div class="icon">
            <font-awesome-icon :icon="['far', 'credit-card']" size="3x" class="mt-3"></font-awesome-icon>
          </div>
          <p class="mt-1 mb-2 equal-height"><strong>{{ record.card_name }}</strong></p>
          {{ record.card_number }}<br />
          Expires: <strong>{{ record.expiry_date }}</strong>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data () {
    return {
      selected: null
    }
  },
  computed: {
    ...mapState({
      user: state => state.user.user
    })
  },
  mounted () {
    if (this.user.cards && this.user.cards.length > 0) {
      this.selected = this.user.cards[0]._id
    }
  },
  watch: {
    selected (_value) {
      this.$emit('card-selected', _value)
    }
  }
}
</script>
