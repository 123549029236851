<template>
  <div>
    <p class="mb-0">
      Choose one of your saved cards below or click "Add new card" to provide new card details.
    </p>
    <choose-cards v-on:card-selected="selectCard"></choose-cards>
  </div>
</template>

<script>
import ChooseCards from './ChooseCards'
import { mapState } from 'vuex'

export default {
  components: {
    ChooseCards
  },
  data () {
    return {
      selected: null
    }
  },
  computed: {
    ...mapState({
      record: state => state.transaction,
      transaction: state => state.transaction.transaction,
      user: state => state.user.user
    }),
    loadStatus () {
      return this.record.status
    }
  },
  methods: {
    selectCard: function (value) {
      this.selected = value
    }
  },
  watch: {
    selected (value) {
      if (value) {
        this.$emit('update-card-id', value)
        this.$emit('update-can-pay', true)
      } else {
        this.$emit('update-card-id', null)
        this.$emit('update-can-pay', false)
      }
    }
  }
}
</script>
