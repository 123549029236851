<template>
  <div>
    <p class="mb-3">
      <b>Truzo wallet</b> is an easy, secure and affordable way to transact online and offline. Your wallet balance is displayed below.
    </p>
    <div v-if="loadStatus === 'loaded' && walletStatus === 'loaded'" class="mb-0 mb-md-5">
      <b-form-group>
        <p class="color-grey mb-0">Available<br />
        <strong class="font-size-24">{{ wallet[this.currency].wallet.available | formatAmount(currency) }}</strong></p>
        <p class="text-danger mb-0" v-if="!canPay">Unfortunately you have insufficient funds in your wallet. Please, choose another payment method.</p>
      </b-form-group>
      <b-form-group v-if="!updated && canPay">
        <p class="color-grey mb-0">Wallet balance<br />
        <strong>{{ wallet[currency].wallet.available - totalPayable | formatAmount(currency) }}</strong></p>
      </b-form-group>
      <b-form-group v-if="updated && canPay">
        <p class="color-grey mb-0">Wallet balance<br />
        <strong>{{ wallet[currency].wallet.available - (totalPayable - transaction.linked[0].id.amounts.amount) | formatAmount(currency) }}</strong></p>
      </b-form-group>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  data () {
    return {
      selected: null,
      buyerSplit: null
    }
  },
  computed: {
    ...mapGetters('currency', {
      currencyObject: 'fullObject'
    }),
    ...mapState({
      payments: state => state.payments,
      record: state => state.transaction,
      transaction: state => state.transaction.transaction,
      wallet: state => state.wallet
    }),
    loadStatus () {
      return this.record.status
    },
    paymentStatus () {
      return this.payments.status
    },
    walletStatus () {
      return this.wallet[this.currency].status
    },
    buyerFee () {
      return this.selected.fee * this.transaction.amounts.fee_payable_by.buyer_percentage / 100
    },
    buyerComm () {
      if (this.buyerSplit.agent_comm && this.buyerSplit.agent_comm.length > 0) {
        return this.buyerSplit.agent_comm[0]
      } else {
        return null
      }
    },
    totalPayable () {
      let _total = this.buyerFee * (1 + this.txnCurrency(this.transaction).vat)
      _total += this.transaction.amounts.amount
      _total += this.buyerComm ? this.buyerComm.fee : 0
      return _total
    },
    updated () {
      if (this.transaction) {
        return this.transaction.linked && this.transaction.linked.length > 0 && this.transaction.linked[0].id.payment_status !== 'Pending'
      } else {
        return false
      }
    },
    currency () {
      return this.txnCurrency(this.transaction).value
    },
    canPay () {
      if (this.walletStatus === 'loaded') {
        return this.wallet[this.currency].wallet.available >= (this.updated ? this.totalPayable - this.transaction.linked[0].id.amounts.amount : this.totalPayable)
      } else {
        return false
      }
    }
  },
  created () {
    if (this.loadStatus === 'loaded') {
      this.selected = this.transaction.amounts.fees.find(fee => (typeof fee.method === 'object' && fee.method !== null ? fee.method.name.toLowerCase() : fee.method.toLowerCase()) === this.$route.params.method)
      this.buyerSplit = this.transaction.amounts.split.find(split => split.actor === 'Buyer')
      this.getWallet(this.currency)
    }
  },
  methods: {
    ...mapActions('transaction', {
      get: 'get'
    }),
    ...mapActions('payments', {
      pay: 'payWallet'
    }),
    ...mapActions('wallet', {
      getWallet: 'get'
    }),
    txnCurrency: function (transaction) {
      return this.currencyObject(transaction ? transaction.amounts.currency : 'ZAR')
    },
    complete: function () {
      this.pay(this.transaction._id)
    },
    chooseMethod: function () {
      this.$router.push('/transactions/' + this.transaction._id)
      this.$bvModal.show('transaction_pay')
    }
  },
  watch: {
    walletStatus (_status) {
      if (_status === 'loaded') {
        this.$emit('update-can-pay', this.canPay)
      }
    }
  }
}
</script>
