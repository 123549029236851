<template>
  <b-modal id="stitchfund-modal" size="lg" centered hide-header hide-footer no-close-on-backdrop no-close-on-esc :hide-backdrop="isEmbedded" :modal-class="isEmbedded ? 'embedded' : null">
    <form id="stitchfund_form" ref="stitchfund_form" :action="_url" method="GET" target="stitchfund_iframe">
      <input type="hidden" :name="item.name" :value="item.value" v-for="(item, $index) in _parameters" :key="$index" />
    </form>
    <iframe style="border: 0px; width: 100%; height: 640px" name="stitchfund_iframe" sandbox="allow-same-origin allow-scripts allow-forms"></iframe>
  </b-modal>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  data () {
    return {
      interval: undefined
    }
  },
  props: {
    url: String,
    id: String,
    reference: String,
    hash: String
  },
  computed: {
    ...mapState({
      payments: state => state.payments
    }),
    _url () {
      if (this.url) {
        const _split = this.url.split('?')
        return _split[0]
      } else {
        return null
      }
    },
    _parameters () {
      if (this.url) {
        const _split = this.url.split('?')
        const _parameters = _split[1].split('&')
        if (_parameters.length > 0) {
          const __parameters = []
          _parameters.forEach(_parameter => {
            const __parameter = _parameter.split('=')
            __parameters.push({
              name: __parameter[0],
              value: __parameter[1]
            })
          })

          return __parameters
        } else {
          return []
        }
      } else {
        return []
      }
    },
    paymentStatus () {
      return this.payments.status
    },
    isEmbedded () {
      if (this.$route.path.indexOf('embedded') >= 0) {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    ...mapActions('payments', {
      pollStitch: 'pollStitch'
    }),
    submitForm: function () {
      const self = this
      this.$nextTick(() => {
        setTimeout(function () {
          const _form = document.getElementById('stitchfund_form')
          _form.submit()

          self.startStitchPolling()
        }, 1000)
      })
    },
    startStitchPolling: function () {
      const self = this
      this.interval = setInterval(() => {
        self.pollStitch({ id: self.id, reference: self.reference, hash: self.hash, interval: self.interval })
      }, 1000)
    }
  },
  watch: {
    paymentStatus (_status) {
      if (_status === 'failed') {
        clearInterval(this.interval)
        this.interval = null

        this.$bvModal.hide('stitchfund-modal')
      } else if (_status === 'paid') {
        clearInterval(this.interval)
        this.interval = null

        this.$bvModal.hide('stitchfund-modal')
      }
    }
  }
}
</script>
